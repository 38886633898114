import { useState } from 'react';
import {ListResultDto} from "@/services/data";
import {HotBlogDto, WxMiniProgramDto} from "@/pages/front/home/data";

const useHome = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [YTBUrl, setYTBUrl] = useState<WxMiniProgramDto|any>({});
  const [homeBlogs, setHomeBlogs] = useState<ListResultDto<HotBlogDto>>({items:[]});
  return {
    open,
    setOpen,
    homeBlogs,
    setHomeBlogs,
    YTBUrl,
    setYTBUrl
  };
};
export default useHome;
