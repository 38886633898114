// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'D:/大头车开发/Shakesoft.DTCLG/h5/template/fast_h5_umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/account",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'@/layouts/UserLayout')}),
    "routes": [
      {
        "path": "/account/login",
        "title": "登录",
        "name": "login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__login' */'@/pages/account/login')}),
        "exact": true
      },
      {
        "path": "/account/register",
        "title": "注册",
        "name": "register",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__register' */'@/pages/account/register')}),
        "exact": true
      },
      {
        "path": "/account/forget",
        "title": "找回密码",
        "name": "forget",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__forget' */'@/pages/account/forget')}),
        "exact": true
      }
    ]
  },
  {
    "path": "/exception",
    "routes": [
      {
        "path": "/exception/403",
        "name": "not-permission",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exception__403' */'@/pages/exception/403')}),
        "exact": true
      },
      {
        "path": "/exception/404",
        "name": "not-find",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exception__404' */'@/pages/exception/404')}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'@/layouts/BasicLayout')}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/Authorized')})],
    "routes": [
      {
        "path": "/",
        "redirect": "/home",
        "exact": true
      },
      {
        "path": "/home",
        "title": "首页",
        "name": "home",
        "isMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__home' */'@/pages/front/home')}),
        "exact": true
      },
      {
        "path": "/order",
        "title": "在线下单",
        "name": "order",
        "isMenu": false,
        "routes": [
          {
            "path": "/order/index",
            "title": "在线发货",
            "name": "order.index",
            "isMenu": false,
            "exact": true,
            "authority": [
              "user"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__order' */'@/pages/front/order')})
          },
          {
            "path": "/order/finder",
            "title": "运单查询",
            "name": "order.finder",
            "isMenu": false,
            "exact": true,
            "authority": [
              "user"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__order__finder' */'@/pages/front/order/finder')})
          }
        ]
      },
      {
        "path": "/about",
        "title": "关于我们",
        "name": "about",
        "isMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__about' */'@/pages/front/service/about')}),
        "exact": true
      },
      {
        "path": "/expand",
        "title": "运力加盟",
        "name": "expand",
        "exact": true,
        "isMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__join' */'@/pages/front/service/join')}),
        "routes": [
          {
            "path": "/expand/auth",
            "title": "权限页面",
            "name": "expand.auth",
            "authority": [
              "admin"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__expand__auth' */'@/pages/front/service/expand/auth')}),
            "exact": true
          }
        ]
      },
      {
        "path": "/convention",
        "title": "诚信联盟公约",
        "name": "convention",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__convention' */'@/pages/front/service/convention')}),
        "exact": true
      },
      {
        "path": "/join",
        "exact": true,
        "isMenu": false,
        "title": "在线加盟",
        "name": "join-us",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__expand' */'@/pages/front/service/expand')})
      },
      {
        "path": "/online",
        "exact": true,
        "title": "在线加盟",
        "name": "join-online",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__join__online' */'@/pages/front/service/join/online')})
      },
      {
        "path": "/product",
        "title": "产品与服务",
        "name": "product",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__product' */'@/pages/front/service/product')}),
        "exact": true
      },
      {
        "path": "/truck",
        "title": "车型&物流装备",
        "name": "truck",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__truck' */'@/pages/front/service/truck')}),
        "exact": true
      },
      {
        "path": "/founder",
        "title": "创始人与团队",
        "name": "truck",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__founder' */'@/pages/front/service/founder')}),
        "exact": true
      },
      {
        "path": "/job",
        "title": "招贤纳士",
        "name": "truck",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__job' */'@/pages/front/service/job')}),
        "exact": true
      },
      {
        "path": "/contact",
        "title": "联系我们",
        "name": "truck",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__contact' */'@/pages/front/service/contact')}),
        "exact": true
      },
      {
        "path": "/article",
        "title": "新闻资讯",
        "name": "article",
        "routes": [
          {
            "path": "/article/:slug",
            "title": "新闻资讯",
            "name": "news-center",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__article' */'@/pages/front/article')}),
            "exact": true
          },
          {
            "path": "/article/:blog/:slug",
            "title": "新闻资讯",
            "name": "news-detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__article__news' */'@/pages/front/article/news')}),
            "exact": true
          }
        ]
      },
      {
        "path": "/question/account",
        "title": "账号相关",
        "name": "question",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__question__account' */'@/pages/front/service/question/account')}),
        "exact": true
      },
      {
        "path": "/question/order",
        "title": "订单相关",
        "name": "question",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__question__order' */'@/pages/front/service/question/order')}),
        "exact": true
      },
      {
        "path": "/question/payment",
        "title": "支付相关",
        "name": "question",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__question__payment' */'@/pages/front/service/question/payment')}),
        "exact": true
      },
      {
        "path": "/question/sell",
        "title": "订单相关",
        "name": "question",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__question__sell' */'@/pages/front/service/question/sell')}),
        "exact": true
      },
      {
        "path": "/question/join",
        "title": "加盟相关",
        "name": "question",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__front__service__question__join' */'@/pages/front/service/question/join')}),
        "exact": true
      },
      {
        "path": "/mine",
        "title": "我的",
        "name": "mine",
        "authority": [
          "user",
          "admin"
        ],
        "isMenu": true,
        "routes": [
          {
            "path": "/mine",
            "title": "我的",
            "name": "mine",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__mine' */'@/pages/member/mine')}),
            "exact": true
          },
          {
            "path": "/mine/setting",
            "title": "个人信息",
            "name": "setting",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__setting' */'@/pages/member/setting')}),
            "exact": true
          },
          {
            "title": "我的订单",
            "name": "order",
            "leftContent": "返回",
            "path": "/mine/order",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__order' */'@/pages/member/order')}),
            "exact": true
          },
          {
            "title": "订单详情",
            "name": "orderdetail",
            "leftContent": "返回",
            "path": "/mine/order/detail/:id",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__order__detail' */'@/pages/member/order/detail')}),
            "exact": true
          },
          {
            "title": "投诉建议",
            "name": "complaint",
            "path": "/mine/complaint",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__complaint' */'@/pages/member/complaint')}),
            "exact": true
          },
          {
            "title": "发货地址",
            "name": "deliver",
            "path": "/mine/deliver",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__address__deliver' */'@/pages/member/address/deliver')}),
            "exact": true
          },
          {
            "title": "收货地址",
            "name": "receive",
            "path": "/mine/receive",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__address__receive' */'@/pages/member/address/receive')}),
            "exact": true
          },
          {
            "title": "回单地址",
            "name": "receipt",
            "path": "/mine/receipt",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__address__receipt' */'@/pages/member/address/receipt')}),
            "exact": true
          },
          {
            "title": "修改发货地址",
            "name": "editdeliver",
            "path": "/mine/deliver/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__address__deliver__edit' */'@/pages/member/address/deliver/edit')}),
            "exact": true
          },
          {
            "title": "修改收货地址",
            "name": "editreceive",
            "path": "/mine/receive/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__address__receive__edit' */'@/pages/member/address/receive/edit')}),
            "exact": true
          },
          {
            "title": "修改回单地址",
            "name": "editreceipt",
            "path": "/mine/receipt/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__address__receipt__edit' */'@/pages/member/address/receipt/edit')}),
            "exact": true
          },
          {
            "title": "新增发货地址",
            "name": "adddeliver",
            "path": "/mine/deliver/add",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__address__deliver__edit' */'@/pages/member/address/deliver/edit')}),
            "exact": true
          },
          {
            "title": "新增收货地址",
            "name": "addreceive",
            "path": "/mine/receive/add",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__address__receive__edit' */'@/pages/member/address/receive/edit')}),
            "exact": true
          },
          {
            "title": "新增回单地址",
            "name": "addreceipt",
            "path": "/mine/receipt/add",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__member__address__receipt__edit' */'@/pages/member/address/receipt/edit')}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exception__404' */'@/pages/exception/404')}),
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exception__404' */'@/pages/exception/404')}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
