import { Toast } from 'antd-mobile-v5';
import Report from '@/utils/report';
import appConfig, { DefaultConfig } from '../config/appConfig';
import {getConfiguration} from "@/services/global";
import {setAuthority} from "@/utils/authority";
import _ from 'lodash';
console.info('当前app环境---->', PRO_VAR);
const { enableVConsole, enableSentry }: DefaultConfig = appConfig;
if (enableVConsole) {
  console.log('vConsole init success');
  // @ts-ignore
  new VConsole();
}

window.recaptchaOptions = {
  useRecaptchaNet: true,
  enterprise: true,
};

// Toast mask
Toast.config({
  maskClickable: false,
});

if (enableSentry && PRO_VAR !== 'mock' && !NODE_IS_DEV) {
  const sentry = Report.getInstance({
    dsn: '',
    release: RELEASE_VERSION, // from webpack DefinePlugin
    environment: PRO_VAR,
    debug: PRO_VAR === 'dev' || PRO_VAR === 'test',
  });
  window.$sentry = sentry;
}

// @ts-ignore
export function onRouteChange({ location, matchedRoutes }) {
  //console.log('onRouteChange', location, matchedRoutes);
}

export async function getInitialState() {
  const data = await getConfiguration();
  //角色加功能权限
  // @ts-ignore
  setAuthority(_.keys(data.auth.grantedPolicies).concat(data.currentUser.roles));
  return data;
}
