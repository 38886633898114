import {useState} from "react";
import {
  DeliverAddressListDto,
  GetBlogsInputDto,
} from "@/pages/member/address/deliver/data";
import {ReceiveAddressCreateOrEditInput} from "@/pages/member/address/receive/data";

const useReceiveAddress = () => {
  const [title, setTitle] = useState<string>('新增收货地址');
  const [hasMore, setHasMore] = useState(true);
  const defaultRequestParams: GetBlogsInputDto = {
    skipCount: 0,
    maxResultCount: 5,
    filter: "",
    sorting: "",
  };
  const [requestParams, setRequestParams] = useState<GetBlogsInputDto>(defaultRequestParams);
  const [receiveAddressList, setReceiveAddressList] = useState<DeliverAddressListDto[]>([]);
  const [receiveAddressEdit, setReceiveAddressEdit] = useState<ReceiveAddressCreateOrEditInput|any>({});
  return {
    title,
    setTitle,
    hasMore,
    setHasMore,
    requestParams,
    setRequestParams,
    receiveAddressList,
    setReceiveAddressList,
    receiveAddressEdit,
    setReceiveAddressEdit
  };
};
export default useReceiveAddress;
