import { Reducer, Effect } from 'umi';
import { setAuthority } from '@/utils/authority';
import { AuthorityStatus } from '@/common';
import { useModel } from 'umi';

export interface IUserModelState {
  currentUser: APP.ICurrentUser | null
  currentAuthority: 'user'|'guest';
}

export interface IUserModel {
  namespace: 'user';
  state: IUserModelState;
  effects: {
    // 退出登录
    fetchLogout: Effect;
    // 检查登录状态与权限
    fetchLoginStatus: Effect;
  };
  reducers: {
    save: Reducer<IUserModelState>;
  };
}

const UserModel: IUserModel = {
  namespace: 'user',
  state: {
    currentUser: null,
    currentAuthority: 'guest',
  },
  effects: {
    /**
     * 退出登录
     */
    *fetchLogout({ payload }, { call, put }) {
      yield console.log('退出登录');
    },
    /**
     * 检查登录状态与权限
     */
    *fetchLoginStatus({ payload }, { put }) {
      // 没有用户信息 没登录
      if (!payload.id) {
        yield put({
          type: 'save',
          payload: {
            currentUser:null,
            currentAuthority: 'guest',
          },
        });
        return;
      }
      else {
        yield put({
          type: 'save',
          payload: {
            currentUser: payload,
            currentAuthority: 'user'
          },
        });
      }
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    }
  },
};

export default UserModel;
