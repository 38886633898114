import {useState} from "react";
import {LogisticsOrderDetailDto} from "@/pages/member/order/data";

const useOrderDetail = () => {
  const [orderDetail, setOrderDetail] = useState<LogisticsOrderDetailDto | any>({});
  return {
    orderDetail,
    setOrderDetail,
  };
};
export default useOrderDetail;
