import {useState} from "react";
import {GetOrderInputDto, LogisticsOrderListDto} from "@/pages/member/order/data";

const useOrderList = () => {
  const [hasMore, setHasMore] = useState(true);
  const defaultRequestParams: GetOrderInputDto = {
    skipCount: 0,
    maxResultCount: 5,
    filter: "",
    sorting: "",
  };
  const [requestParams, setRequestParams] = useState<GetOrderInputDto>(defaultRequestParams);
  const [orderList, setOrderList] = useState<LogisticsOrderListDto[]>([]);

  return {
    hasMore,
    setHasMore,
    requestParams,
    setRequestParams,
    orderList,
    setOrderList,
  };
};
export default useOrderList;
