import {useState} from "react";
import {MemberInfoDto} from "@/pages/member/setting/data";
import {LogisticsOrderQuantityStatisticsDto, MemberDto} from "@/pages/member/mine/data";

const useMemberInfo = () => {
  const [member, setMember] = useState<MemberDto | any>({});
  const [memberInfo, setMemberInfo] = useState<MemberInfoDto | any>({});
  const [logisticStatistics, setLogisticStatistics] = useState<LogisticsOrderQuantityStatisticsDto | any>({});

  return {
    memberInfo,
    setMemberInfo,
    member,
    setMember,
    logisticStatistics,
    setLogisticStatistics
  };
};
export default useMemberInfo;
