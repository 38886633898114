import { useState } from 'react';
import {BlogDto, BlogPostPublicDto} from "@/pages/front/home/data";
import {
  GetBlogsInputDto
} from "@/pages/member/address/deliver/data";

const useBlog = () => {
  const [blogs, setBlogs] = useState<BlogDto[]>([]);

  const [hasMore, setHasMore] = useState(true);
  const defaultRequestParams: GetBlogsInputDto = {
    skipCount: 0,
    maxResultCount: 5,
    filter: "",
    sorting: "",
  };
  const [requestParams, setRequestParams] = useState<GetBlogsInputDto>(defaultRequestParams);
  const [articleList, setArticleList] = useState<BlogPostPublicDto[]>([]);
  const [articleEdit, setArticleEdit] = useState<BlogPostPublicDto|any>({});

  return {
    blogs,
    setBlogs,

    hasMore,
    setHasMore,
    requestParams,
    setRequestParams,
    articleList,
    setArticleList,
    articleEdit,
    setArticleEdit
  };
};

export default useBlog;
