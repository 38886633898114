/**
 * 默认配置
 */
export interface DefaultConfig {
  appName: string;
  enableVConsole: boolean;
  appPrimary: string;
  axiosTimeout: number;

  axiosCookie: boolean;
  axiosBaseUrl: string;
  authorityKey: string;
  enableSentry: boolean;
  baiduAnalyticsKey: string;
  googleAnalyticsKeyKey: string;
}

const appConfig: DefaultConfig = {
  appName: '大头车物流', // 项目title
  enableVConsole: false, // 开启vconsole
  appPrimary: '#5B98FF',
  axiosTimeout: 10000,
  axiosCookie: true,
  // axiosBaseUrl: 'https://localhost:44362',
  axiosBaseUrl: 'https://www.dtc56.com',
  authorityKey: 'dtc-h5-authority',
  enableSentry: true,
  baiduAnalyticsKey: '',
  googleAnalyticsKeyKey: '',
};

export default appConfig;
