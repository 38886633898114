// @ts-nocheck
import React from 'react';
import initialState from 'D:/大头车开发/Shakesoft.DTCLG/h5/template/fast_h5_umi/src/.umi-production/plugin-initial-state/models/initialState';
import model0 from "D:/大头车开发/Shakesoft.DTCLG/h5/template/fast_h5_umi/src/models/blogState";
import model1 from "D:/大头车开发/Shakesoft.DTCLG/h5/template/fast_h5_umi/src/models/deliverAddressState";
import model2 from "D:/大头车开发/Shakesoft.DTCLG/h5/template/fast_h5_umi/src/models/homeState";
import model3 from "D:/大头车开发/Shakesoft.DTCLG/h5/template/fast_h5_umi/src/models/memberInfoState";
import model4 from "D:/大头车开发/Shakesoft.DTCLG/h5/template/fast_h5_umi/src/models/orderDetailState";
import model5 from "D:/大头车开发/Shakesoft.DTCLG/h5/template/fast_h5_umi/src/models/orderListState";
import model6 from "D:/大头车开发/Shakesoft.DTCLG/h5/template/fast_h5_umi/src/models/receiptAddressState";
import model7 from "D:/大头车开发/Shakesoft.DTCLG/h5/template/fast_h5_umi/src/models/receiveAddressState";
import model8 from "D:/大头车开发/Shakesoft.DTCLG/h5/template/fast_h5_umi/src/models/userState";
// @ts-ignore
import Dispatcher from './helpers/dispatcher';
// @ts-ignore
import Executor from './helpers/executor';
// @ts-ignore
import { UmiContext } from './helpers/constant';

export const models = { '@@initialState': initialState, 'blogState': model0, 'deliverAddressState': model1, 'homeState': model2, 'memberInfoState': model3, 'orderDetailState': model4, 'orderListState': model5, 'receiptAddressState': model6, 'receiveAddressState': model7, 'userState': model8 };

export type Model<T extends keyof typeof models> = {
  [key in keyof typeof models]: ReturnType<typeof models[T]>;
};

export type Models<T extends keyof typeof models> = Model<T>[T]

const dispatcher = new Dispatcher!();
const Exe = Executor!;

export default ({ children }: { children: React.ReactNode }) => {

  return (
    <UmiContext.Provider value={dispatcher}>
      {
        Object.entries(models).map(pair => (
          <Exe key={pair[0]} namespace={pair[0]} hook={pair[1] as any} onUpdate={(val: any) => {
            const [ns] = pair as [keyof typeof models, any];
            dispatcher.data[ns] = val;
            dispatcher.update(ns);
          }} />
        ))
      }
      {children}
    </UmiContext.Provider>
  )
}
