import {useState} from "react";
import {
  DeliverAddressCreateOrEditInput,
  DeliverAddressListDto,
  GetBlogsInputDto
} from "@/pages/member/address/deliver/data";

const useDeliverAddress = () => {
  const [title, setTitle] = useState<string>('新增发货地址');
  const [hasMore, setHasMore] = useState(true);
  const defaultRequestParams: GetBlogsInputDto = {
    skipCount: 0,
    maxResultCount: 5,
    filter: "",
    sorting: "",
  };
  const [requestParams, setRequestParams] = useState<GetBlogsInputDto>(defaultRequestParams);
  const [deliverAddressList, setDeliverAddressList] = useState<DeliverAddressListDto[]>([]);
  const [deliverAddressEdit, setDeliverAddressEdit] = useState<DeliverAddressCreateOrEditInput|any>({});
  return {
    title,
    setTitle,
    hasMore,
    setHasMore,
    requestParams,
    setRequestParams,
    deliverAddressList,
    setDeliverAddressList,
    deliverAddressEdit,
    setDeliverAddressEdit
  };
};
export default useDeliverAddress;
