import {useState} from "react";
import {
  ReceiptAddressCreateOrEditInput,
  ReceiptAddressListDto,
  GetBlogsInputDto
} from "@/pages/member/address/receipt/data";

const useReceiptAddress = () => {
  const [title, setTitle] = useState<string>('新增回单地址');
  const [hasMore, setHasMore] = useState(true);
  const defaultRequestParams: GetBlogsInputDto = {
    skipCount: 0,
    maxResultCount: 5,
    filter: "",
    sorting: "",
  };
  const [requestParams, setRequestParams] = useState<GetBlogsInputDto>(defaultRequestParams);
  const [receiptAddressList, setReceiptAddressList] = useState<ReceiptAddressListDto[]>([]);
  const [receiptAddressEdit, setReceiptAddressEdit] = useState<ReceiptAddressCreateOrEditInput|any>({});
  return {
    title,
    setTitle,
    hasMore,
    setHasMore,
    requestParams,
    setRequestParams,
    receiptAddressList,
    setReceiptAddressList,
    receiptAddressEdit,
    setReceiptAddressEdit
  };
};
export default useReceiptAddress;
